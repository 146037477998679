import React, { useState } from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import { StoreContext } from '../context/store-context'
import { LineItem } from '../components/shopify/line-item'
import { formatPrice } from '../utils/format-price'
import {
  table,
  wrap,
  totals,
  grandTotal,
  summary,
  checkoutButton,
  collapseColumn,
  labelColumn,
  imageHeader,
  productHeader,
  emptyStateContainer,
  emptyStateHeading,
  emptyStateLink,
  title,
} from '../style/shop/cart.module.css'
import { colors, s } from '../style/'
import { sButton, sButtonTrade } from '../components/Button'

export default function CartPage() {
  const { checkout, loading } = React.useContext(StoreContext)
  const emptyCart = checkout.lineItems.length === 0
  const [agree, setAgree] = useState(false)
  const [agree2, setAgree2] = useState(false)

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  const checkboxHandler = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setAgree(!agree)
    // Don't miss the exclamation mark
  }

  const checkboxHandler2 = () => {
    // if agree === true, it will be set to false
    // if agree === false, it will be set to true
    setAgree2(!agree2)
    // Don't miss the exclamation mark
  }

  return (
    <Layout white>
      <section css={sOuter}>
        <div css={[sShopWrapper, wrap]}>
          {emptyCart ? (
            <div className={emptyStateContainer}>
              <h1 className={emptyStateHeading}>Twój koszyk jest pusty</h1>
              <p>
                Wygląda na to, że jeszcze nic nie znalazłeś. Rozumiemy to
                czasami trudno wybrać — może to pomoże:
              </p>
              <div css={sButtonWrapper}>
                <Link to={`/sklep`} css={[sButton, sButtonTrade]}>
                  Przejdź do sklepu
                </Link>
              </div>
            </div>
          ) : (
            <>
              <h1 css={sHeadline}>Twój koszyk</h1>
              <table className={table}>
                <thead>
                  <tr>
                    <th className={imageHeader}>Zdjęcie produktu</th>
                    <th className={productHeader}>Produkt</th>
                    <th className={collapseColumn}>Cena</th>
                    <th>Ilość</th>
                    <th className={[totals, collapseColumn].join(' ')}>
                      Razem
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {checkout.lineItems.map((item) => (
                    <LineItem item={item} key={item.id} />
                  ))}

                  <tr className={summary}>
                    <td className={collapseColumn}></td>
                    <td className={collapseColumn}></td>
                    <td className={collapseColumn}></td>
                    <td className={labelColumn}>Kwota</td>
                    <td className={totals}>
                      {formatPrice(
                        checkout.subtotalPriceV2.currencyCode,
                        checkout.subtotalPriceV2.amount
                      )}
                    </td>
                  </tr>
                  {/* <tr className={summary}>
                    <td className={collapseColumn}></td>
                    <td className={collapseColumn}></td>
                    <td className={collapseColumn}></td>
                    <td className={labelColumn}>VAT</td>
                    <td className={totals}>
                      {formatPrice(
                        checkout.totalTaxV2.currencyCode,
                        checkout.totalTaxV2.amount
                      )}
                    </td>
                  </tr> */}
                  <tr className={summary}>
                    <td className={collapseColumn}></td>
                    <td className={collapseColumn}></td>
                    <td className={collapseColumn}></td>
                    <td className={labelColumn}>Dostawa</td>
                    <td className={totals}>Cena nie zawiera dostawy</td>
                  </tr>
                  <tr className={grandTotal}>
                    <td className={collapseColumn}></td>
                    <td className={collapseColumn}></td>
                    <td className={collapseColumn}></td>
                    <td className={labelColumn}>Łącznie</td>
                    <td className={totals}>
                      {formatPrice(
                        checkout.totalPriceV2.currencyCode,
                        checkout.totalPriceV2.amount
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div css={sButtonWrapper}>
                <div css={sCheckboxAgreementWrapper}>
                  <div css={sCheckboxAgreement}>
                    <input
                      type='checkbox'
                      id='agree'
                      onChange={checkboxHandler}
                      required
                    />
                    <label htmlFor='agree'>
                      * Zapoznałem się z informacjami dotyczącymi{' '}
                      <a
                        href='/pdf/zasady-ochrony-danych.pdf'
                        target='_blank'
                        rel='nofollow noreferrer noopener'>
                        zasad ochrony danych osobowych
                      </a>
                      .
                    </label>
                  </div>
                  <div css={sCheckboxAgreement}>
                    <input type='checkbox' id='agree2' />
                    <label htmlFor='agree2'>
                      Wyrażam zgodę na otrzymywanie drogą elektroniczną
                      informacji handlowych w rozumieniu ustawy z dnia 18 lipca
                      2002 r. o świadczeniu usług drogą elektroniczną o treści
                      pochodzących od Omida Logistics Sp. z o.o.
                    </label>
                  </div>
                  <div css={sCheckboxAgreement}>
                    <input type='checkbox' id='agree3' />
                    <label htmlFor='agree3'>
                      Wyrażam zgodę na wykorzystywanie przez Omida Logistics Sp.
                      z o.o. telekomunikacyjnych urządzeń końcowych i
                      automatycznych systemów wywołujących celem przedstawienia
                      informacji handlowych w rozumieniu przepisów ustawy z dnia
                      16 lipca 2014 r. - Prawo telekomunikacyjne
                    </label>
                  </div>
                </div>
                <button
                  onClick={handleCheckout}
                  disabled={!agree}
                  css={[sButton, sButtonTrade]}>
                  {agree
                    ? 'Przejdź do podsumowania'
                    : 'Zaznacz wymagane'}
                </button>
              </div>
            </>
          )}
        </div>
      </section>
    </Layout>
  )
}

const sOuter = {
  height: 'max-content',
  minHeight: '100vh',
  width: '100%',
  position: 'relative',
  top: 0,
  left: 0,
  padding: '12rem 0 10rem',
  [s.xs]: {
    padding: '8rem 0',
  },
}

const sShopWrapper = {
  width: '100vw',
  maxWidth: 1500,
  margin: 'auto',
  marginTop: '2rem',
}

const sHeadline = {
  width: '100vw',
  maxWidth: '1500px',
  margin: 'auto',
  fontSize: '2rem',
  marginBottom: '2rem',
  padding: '0 2rem',
  [s.md]: {
    padding: '0 4rem',
  },
}

const sButtonWrapper = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '4rem',
  padding: '0 2rem',
  [s.md]: {
    padding: '0 4rem',
  },
  button: {
    '&:disabled': {
      background: '#F5F5F5',
      color: '#2020204D',
      boxShadow: 'unset',
      pointerEvents: 'none',
    },
  },
}

const sTable = {
  padding: '0 2rem',
  [s.md]: {
    padding: '0 4rem',
  },
}

const sCheckboxAgreementWrapper = {
  maxWidth: '600px',
  margin: '0 auto',
}

const sCheckboxAgreement = {
  margin: '3rem auto',
  display: 'flex',
  alignItems: 'flex-start',
  ':first-of-type': {
    marginBottom: '0',
  },
  ':nth-of-type(2)': {
    marginTop: '1rem',
    marginBottom: '0',
  },
  ':nth-of-type(3)': {
    marginTop: '1rem',
  },
  [s.md]: {},
  input: {
    padding: '0',
    height: 'initial',
    width: 'initial',
    marginBottom: '0',
    display: 'none',
    cursor: 'pointer',
    '&:checked + label::after': {
      content: '""',
      position: 'absolute',
      top: '5px',
      left: '5px',
      display: 'block',
      width: '10px',
      height: '10px',
      backgroundColor: '#34334060',
      borderRadius: '3px',
    },
  },
  label: {
    position: 'relative',
    cursor: 'pointer',
    paddingLeft: '35px',
    fontSize: '14px',
    '&::before': {
      content: '""',
      position: 'absolute',
      top: '0',
      left: '0',
      display: 'block',
      width: '20px',
      height: '20px',
      backgroundColor: '#34334014',
      borderRadius: '5px',
    },
    a: {
      color: '#5E62BD',
      '&:visited': {
        color: '#5E62BD',
      },
    },
  },
}
